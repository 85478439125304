import { useEffect } from 'react';
import { NormalizedProduct, NormalizedVariant } from '@ts/index';
import { LENS_COLORS } from '@constants';
import { FRAME_COLORS, generateSunglassImages } from '@utils/index';
import styles from './DropHint.module.scss';

type DropHintProps = {
	product: NormalizedProduct;
	variant: NormalizedVariant;
	lensColor: LENS_COLORS;
};

function DropHint({ product, variant, lensColor }: DropHintProps) {
	const { id: productId, name: titleWithoutSubtitle, images } = product;
	const { id: rawId, image, option } = variant;
	const variantId = rawId?.includes('/ProductVariant/') ? rawId.split('/ProductVariant/')[1] : rawId;
	const imageUrl = !!lensColor
		? generateSunglassImages({
				baseColor: option as FRAME_COLORS,
				lensColor: lensColor,
				length: 1,
				name: titleWithoutSubtitle,
			})[0]
		: (image?.url ?? images[0]);

	useEffect(() => {
		window.Shopify = { shop: 'pair-eyewear.myshopify.com' };
		window.ShopifyAnalytics = {
			meta: {
				product: { id: productId },
				productId: productId,
				variantId: variantId,
				mainProductTitle: titleWithoutSubtitle,
				mainProductImage: imageUrl,
			},
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [product, variant, lensColor]);

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://dropahint.love/js/script.js?shop=pair-eyewear.myshopify.com';
		script.id = 'drophint-script';
		document.body.appendChild(script);
	}, []);

	return (
		<>
			<div id='dropahint_wrapper' className={styles.container} />
		</>
	);
}

export default DropHint;

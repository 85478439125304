import React from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { getProduct } from '@services/shopify';
import { NormalizedProduct, NormalizedVariant, ProductMetafields } from '@ts/product';
import { BASE_FRAME_NAMES, LOCALE_DICT } from '@constants';
import { ComponentHeader, Flex, HeartSparkle, HorizontalCard } from '@components';
import styles from './BundleUpsell.module.scss';

type BundleUpsellProps = {
	bundleHandles: ProductMetafields['parentBundles'];
	frameShape: (typeof BASE_FRAME_NAMES)[number];
};

const BundleUpsell = ({ bundleHandles = [], frameShape }: BundleUpsellProps) => {
	const { locale } = useRouter();

	const {
		data: bundleVariants,
		isLoading,
		isError,
	} = useQuery(['bundleUpsell', bundleHandles, frameShape, locale], async () => {
		const promises = bundleHandles.map(
			handle =>
				getProduct(handle, {
					includeDescription: false,
					skipCollections: true,
					skipImages: true,
					skipVariants: true,
					includeSpecificFrameVariant: true,
					selectedOptions: [{ name: 'Frame', value: frameShape }],
					country: LOCALE_DICT[locale].countryCode,
				}) as Promise<NormalizedVariant>
		);

		const results = await Promise.allSettled(promises);
		return results
			.map(result => (result.status === 'fulfilled' ? result.value : null))
			.filter(Boolean)
			.slice(0, 2);
	});

	if (!bundleHandles.length || isLoading || isError) {
		return null;
	}
	return (
		<div className={styles.container}>
			<ComponentHeader
				title={'Bundle & Save'}
				subtitle='Save up to 15% with pre-set packs!'
				headerArt={<HeartSparkle />}
				className={styles.header}
				withDivider
			/>
			<Flex column gap={3}>
				{bundleVariants &&
					bundleVariants.map((variant, index) => (
						<HorizontalCard
							key={index}
							variant={variant}
							product={variant?.product as NormalizedProduct}
							dataTags={{
								button: {
									'data-add-pdp-bundle-upsell': variant?.product?.name,
								},
								zoom: {},
								favorite: {},
							}}
						/>
					))}
			</Flex>
		</div>
	);
};

export default BundleUpsell;

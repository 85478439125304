import React, { forwardRef } from 'react';
import cn from 'classnames';
import { Caption, Heading } from '@components';
import Flex from '../Flex';
import styles from './ComponentHeader.module.scss';

type ComponentHeaderProps = {
	title: string;
	subtitle?: string;
	withDivider?: boolean;
	headerArt?: React.ReactNode;
	subtitleArt?: React.ReactNode;
	className?: string;
	children?: React.ReactNode;
};

const ComponentHeader = forwardRef<HTMLDivElement, ComponentHeaderProps>(
	({ title, subtitle, withDivider, headerArt, subtitleArt, className, children }, ref) => {
		const classes = cn(className, {
			[styles['withDivider']]: withDivider,
		});

		return (
			<Flex className={classes} ref={ref} column align='start' gap={3}>
				{headerArt}
				<Flex className={styles.content}>
					<div className={styles.copy}>
						<Heading tag='h3' removeDefaultMargin>
							{title}
						</Heading>
						{subtitle && (
							<Flex className={styles.subtitleGroup} gap={2} align='start'>
								{subtitleArt}
								<Caption>{subtitle}</Caption>
							</Flex>
						)}
					</div>
					<div className={styles.buttonContainer}>{children}</div>
				</Flex>
			</Flex>
		);
	}
);

ComponentHeader.displayName = 'ComponentHeader';

export default ComponentHeader;
